<template>
    <FilterModal
        :isLoading="isLoading"
        @filter="getFilterData"
        @reset="resetFilter"
    >
        <template #filters>
            <Dropdown
                name="supplierCode"
                :title="filterTitles.supplierCode"
                :required="true"
                :options="filterOptions.supplierCode"
                v-model="filterPayload.supplierCode"
            />
            <Dropdown
                name="productName"
                placeholder="請輸入商品名稱"
                :searchable="true"
                :title="filterTitles.productName"
                :options="filterOptions.productName"
                v-model="filterPayload.productName"
            />
            <Dropdown
                name="dateType"
                :title="filterTitles.dateType"
                :required="true"
                :options="filterOptions.dateType"
                v-model="filterPayload.dateType"
            />
            <Textbox
                v-if="filterPayload.dateType !== 2"
                name="onSaleDateStart"
                type="date"
                :required="true"
                :title="filterTitles.onSaleDateStart"
                v-model="filterPayload.onSaleDateStart"
                :tips="dateTip"
            />
            <Textbox
                v-if="filterPayload.dateType !== 2"
                name="onSaleDateEnd"
                type="date"
                :required="true"
                :min="getMinDate(filterPayload.onSaleDateStart)"
                :max="getMaxDate(filterPayload.onSaleDateStart)"
                :title="filterTitles.onSaleDateEnd"
                v-model="filterPayload.onSaleDateEnd"
                :tips="dateTip"
            />
            <Textbox
                v-if="filterPayload.dateType !== 1"
                name="offSaleDateStart"
                type="date"
                :required="true"
                :title="filterTitles.offSaleDateStart"
                v-model="filterPayload.offSaleDateStart"
                :tips="dateTip"
            />
            <Textbox
                v-if="filterPayload.dateType !== 1"
                name="offSaleDateEnd"
                type="date"
                :required="true"
                :min="getMinDate(filterPayload.offSaleDateStart)"
                :max="getMaxDate(filterPayload.offSaleDateStart)"
                :title="filterTitles.offSaleDateEnd"
                v-model="filterPayload.offSaleDateEnd"
                :tips="dateTip"
            />
        </template>
    </FilterModal>
</template>
<script>
import FilterModal from '@/containers/FilterModal.vue'
import Textbox from '@/components/Textbox.vue'
import { productSummaryFilterTitles } from '@/assets/javascripts/product/setting'
import {
    getProductSummarySuppliersAPI,
    postProductSummaryProductsAPI
} from '@/assets/javascripts/api'
import Dropdown from '@/components/Dropdown.vue'
import _ from 'lodash'

export default {
    name: 'ProductSummaryFilter',
    components: {
        FilterModal,
        Textbox,
        Dropdown
    },
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getFilterData() {
            if (!_.isEqual(this.perviousFilterPayload, this.filterPayload)) {
                this.perviousFilterPayload = _.cloneDeep(this.filterPayload)
                this.$emit('show-data-table')
            }
            this.filterLog = this.$getFilterLog(this.filterPayload)
        },
        resetFilter() {
            this.filterPayload = {
                supplierCode: this.filterOptions?.supplierCode[0].value || '',
                productName: '',
                dateType: '',
                onSaleDateStart: '',
                onSaleDateEnd: '',
                offSaleDateStart: '',
                offSaleDateEnd: ''
            }
        },
        getDropdownOptions: async function () {
            this.$showLoading()
            try {
                const res = await getProductSummarySuppliersAPI()
                this.dropdownOptions = res.data
            } catch (error) {
                this.dropdownOptions = {}
                this.$showErrorMessage(
                    '載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.$hideLoading()
            }
        },
        getMinDate(startDate) {
            return startDate || undefined
        },
        getMaxDate(startDate) {
            if (!startDate) return ''
            const startDateArr = startDate.split('-')
            startDateArr[0] = `${+startDateArr[0] + 1}`
            const maxDate = startDateArr.join('-')
            return maxDate
        },
        getEndDate(startDate, endDate) {
            let result = endDate
            const start = new Date(startDate)
            const end = new Date(endDate)
            const maxDate = this.getMaxDate(startDate)
            const max = new Date(maxDate)
            if (max < end || !endDate || start > end) result = maxDate

            return result
        }
    },
    computed: {
        filterOptions() {
            return {
                supplierCode: this.dropdownOptions.supplier?.map((data) => {
                    return { label: data.value, value: data.key }
                }),
                dateType: this.dateOption,
                productName: this.productNameOptions.map((data) => {
                    return {
                        label: data.prod_name,
                        value: data.prod_name
                    }
                })
            }
        }
    },
    watch: {
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        },
        'filterPayload.supplierCode': {
            async handler(val) {
                const payload = { supplierCode: val }
                try {
                    this.$showLoading()
                    const res = await postProductSummaryProductsAPI(payload)
                    this.productNameOptions = res.data.products
                } catch (e) {
                    this.productNameOptions = []
                    this.$showErrorMessage(
                        '載入時出現錯誤，請稍後再試',
                        '若持續出現此問題，請聯繫資訊客服'
                    )
                } finally {
                    this.$hideLoading()
                }
            }
        },
        'filterPayload.onSaleDateStart': {
            handler(val) {
                if (!val) return
                this.filterPayload.onSaleDateEnd = this.getEndDate(
                    val,
                    this.filterPayload.onSaleDateEnd
                )
            }
        },
        'filterPayload.offSaleDateStart': {
            handler(val) {
                if (!val) return
                this.filterPayload.offSaleDateEnd = this.getEndDate(
                    val,
                    this.filterPayload.offSaleDateEnd
                )
            }
        },
        'filterPayload.dateType': {
            handler(val) {
                this.$reloadTips()
                switch (val) {
                    case 1:
                        this.filterPayload.offSaleDateStart = ''
                        this.filterPayload.offSaleDateEnd = ''
                        break
                    case 2:
                        this.filterPayload.onSaleDateStart = ''
                        this.filterPayload.onSaleDateEnd = ''
                        break
                }
            }
        }
    },
    data() {
        return {
            filterTitles: {},
            perviousFilterPayload: {},
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp,
            dropdownOptions: {},
            productNameOptions: [],
            dateOption: [
                { label: '全部', value: '' },
                { label: '上架日', value: 1 },
                { label: '停售日', value: 2 }
            ],
            dateTip: '為提供較佳的效能體驗，供查詢的資料區間僅限12個月'
        }
    },
    mounted() {
        this.$nextTick(async () => {
            this.filterTitles = productSummaryFilterTitles
            await this.getDropdownOptions()
            this.resetFilter()
        })
    }
}
</script>
<style lang="scss" scoped></style>
